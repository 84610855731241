html {
    font-family: 'Alegreya sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #333;
    color: #fff;
    scroll-padding-top: calc(36px + 1rem);
}

a {
    color: #b0d0ec;
    text-decoration: 'none';
}

.MuiToolbar-root a {
    color: #fff;
    min-height: 30px;
}

.MuiList-root {
    background-color: #333;
}

.MuiList-root a {
    color: #fff;
}

.MuiPaper-root {
    background-color: #333;
}

.MuiDrawer-paper {
    background-color: #333;
}

@media (min-width: 600px) {
    .MuiToolbar-root a {
        min-height: 30px;
    }
}

.MuiBox-root .MuiButton-root {
    margin-top: 0;
    margin-bottom: 0;
}

.MuiBox-root .MuiIconButton-root {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

footer {
    border-top: 1px solid #555;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.5rem;
}